import React from "react";
import COLORS from "~data/colors.json";
import { Helmet } from "react-helmet";

const Colors = () => {
  const keysArray = Object.keys(COLORS);

  let colorsCss = `:root{`;

  keysArray.forEach((color) => {
    colorsCss += `--color-${color}: ${COLORS[color].hex};`;
  });

  colorsCss += `--cubic-easing: cubic-bezier(0.215, 0.61, 0.355, 1);`;
  colorsCss += `}`;

  return (
    <Helmet>
      <style>{colorsCss}</style>
    </Helmet>
  );
};

export default Colors;
