import React from "react";
import cn from "classnames";
import { useApp } from "~hooks";
import * as styles from "./styles.module.scss";

const Notification = () => {
  const { notificationMessage, isNotificationVisible } = useApp();

  return (
    <div className={styles.container}>
      <p
        className={cn(styles.notification, {
          [styles.active]: isNotificationVisible
        })}
      >
        {notificationMessage}
      </p>
    </div>
  );
};

export default Notification;
